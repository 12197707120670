import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { DrupalTemplate, DrupalNode, DrupalProcessedText, DrupalBanner } from '../drupal'
import { widths } from '../style/variables'
import Layout from '../layouts'
import Container from '../components/Container'
import Stack from '../components/Stack'
import Banner from '../components/Banner'

export interface PageNode extends DrupalNode {
	body: DrupalProcessedText
	relationships: {
		field_banner?: DrupalBanner
	}
}

const StyledContainer = styled(Container)`
	/** Make content more readable by limiting max-width. */
	article {
		max-width: ${widths.md}px;
		margin: 0 auto;
	}
`

const PageTemplate: DrupalTemplate<PageNode> = ({ data: { node } }) => (
	<Layout node={node}>
		<main>
			<Stack>
				<Banner image={node.relationships.field_banner} />

				<StyledContainer>
					<article>
						<h1>{node.title}</h1>
						<div dangerouslySetInnerHTML={{ __html: node.body?.processed }} />
					</article>
				</StyledContainer>
			</Stack>
		</main>
	</Layout>
)

export default PageTemplate

export const pageQuery = graphql`
	query PageTemplateQuery($drupalId: String!) {
		node: nodePage(drupal_id: { eq: $drupalId }) {
			langcode
			title
			metatag_normalized {
				tag
				attributes {
					name
					content
					property
					href
					rel
				}
			}
			body {
				processed
			}
			relationships {
				field_banner {
					...DrupalBanner
				}
			}
		}
	}
`
